//@flow

import React from 'react';

import SEO from '../components/seo';
import { useIntl } from 'gatsby-plugin-intl';

type Props = {};

const FRContent = () => (
  <>
    <SEO title="Mentions légales" />
    <p>
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          window && window.history.back();
        }}
      >
        &lt; Retour
      </a>
    </p>
    <h2>Mentions légales</h2>
    <p>
      <b>Hébergeur du site :</b><br />
      Google Cloud Platform, 8 rue de Londres 75009 Paris
    </p>
    <p>
      <b>Directeur de la publication :</b><br />
      Julien Paccaud, fondateur de My Body Ecology
    </p>

    <p>
      <b>Adresse de courrier électronique :</b><br />
      contact@ecologiecucorps.com
    </p>

    <p>
      <b>Numéro de téléphone :</b><br />
      0681030405
    </p>

    <p>
      <b>Raison sociale :</b><br />
      ECOLOGIE DU CORPS
    </p>

    <p>
      <b>Forme juridique :</b><br />
      sarl
    </p>

    <p>
      <b>Adresse de l’établissement ou du siège social :</b><br />
      81 TER BOULEVARD D’ANDILLY 95230 Soisy-sous-Montmorency
    </p>

    <p>
      <b>Montant du capital social :</b><br />
      40000€
    </p>

    <p>
      <b>Numéro d’inscription au registre du commerce et des sociétés (RCS) :</b><br />
      799638390
    </p>

    <p>
      <b>Numéro individuel d’identification fiscale numéro de TVA intracommunautaire :</b><br />
      34799368390
    </p>
  </>
);

const ENContent = () => (
  <>
    <SEO title="Legal notice" />
    <p>
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          window && window.history.back();
        }}
      >
        &lt; Back
      </a>
    </p>
    <h2>Legal Notice</h2>
    <p>
      <b>Website host:</b><br />
      Google Cloud Platform, 8 rue de Londres 75009 Paris
    </p>
    <p>
      <b>Legal representative:</b><br />
      Julien Paccaud, founder of My Body Ecology
    </p>

    <p>
      <b>Email address:</b><br />
      contact@ecologiecucorps.com
    </p>

    <p>
      <b>Phone number:</b><br />
      0681030405
    </p>

    <p>
      <b>Company name:</b><br />
      ECOLOGIE DU CORPS
    </p>

    <p>
      <b>Legal form:</b><br />
      SARL
    </p>

    <p>
      <b>Address of the establishment or registered office:</b><br />
      81 TER BOULEVARD D’ANDILLY 95230 Soisy-sous-Montmorency
    </p>

    <p>
      <b>Amount of share capital:</b><br />
      40000€
    </p>

    <p>
      <b>SCR Registration Number:</b><br />
      799638390
    </p>

    <p>
      <b>Intracommunity VAT number:</b><br />
      34799368390
    </p>
  </>
);
const LegalPage = () => {
  const intl = useIntl();

  if (intl === 'fr') {
    return <FRContent />;
  }

  return <ENContent />;
}

export default LegalPage;
